import {createReducer} from '@reduxjs/toolkit';
import {message} from 'antd';
import {nanoid} from 'nanoid';
import {DEFAULT_PAGINATION, LOADING_STATUS, ORDER_TYPE,} from '../../helper/constant';
import ROUTE_PATH from '../../router/routePath';
import {formatHlsResponse, getCountAdsInDuration, moveTo} from '../../utils';
import {
  addClipboardMetaData,
  addDayToSchedule,
  addFiller,
  addStreamAds,
  addStreamList,
  applyClipboardToMediaItems,
  changeCurrentHls,
  changeCurrentHlsInfoByKey,
  changeDayInfoByKey,
  changeHlsPage,
  changeHlsPageSize,
  changeItemOrder,
  changeItemOrderDragAndDrop,
  changePlatform,
  changeSelectedItems,
  changeStreamAds,
  clearStream,
  cloneStream,
  deleteClipboardMetaData,
  deleteHlsList,
  deleteStream,
  deleteStreamAd,
  deleteTimelineItem,
  getFillers,
  getHlsDetail,
  getHlsList,
  previewItem,
  previewItemClose,
  previewItemLink,
  previewStream,
  previewStreamClose,
  previewStreamLink,
  removeFiller,
  resetCurrentHls,
  saveEPGIcon,
  saveHls,
  savePlatforms,
  saverename,
  selectStreamDay,
  selectTimeItem,
  setAdsStartTime,
  setStartItem,
  setTimeZones,
  updateLiveStreamitem
} from './hlsActions';
import {getAdvertisementBlockList} from "../advertisementBlock/advertisementBlockActions";
import momentTimezone from "moment-timezone";

const initialState = {
  list: [],
  loadingStatus: LOADING_STATUS.idle,
  currentPage: 1,
  pageSize: DEFAULT_PAGINATION.limit,
  platforms: [],
  ads: [],
  timeZones: [],
  currentHls: {
    title: '',
    streamList: [],
    repeatSchedule: false,
    monetize: false,
    payPerView: false,
    price: 0,
    adsTimer: null,
    timelineFullWidth: false,
    dropZoneVisible: false,
    multiSelect: [],
    selectedDay: null,
    selectedItems: [],
    selectedTimeItem: undefined,
    selectedPlatform: null,
    clipboardMetadata: [],
    status: LOADING_STATUS.idle,
    adsStartTime: 0,
    previewItem: undefined,
    previewStream: undefined,
    epgicon: '',
    fillers: [],
    srt: false,
    timezone: 'PST8PDT',
  },
};

const getTimezone = (state) => {
  return state.currentHls?.timezone ?? initialState?.currentHls?.timezone;
}

const isExpired = (state, item) => {
  const rtmpStream = item?.rtmpStream;
  const expireTime = rtmpStream?.expireTime;
  const timezone = getTimezone(state);
  const now = momentTimezone.tz(timezone);
  const nowToStr = now.format('YYYY-MM-DD HH:mm:ss');

  if (expireTime) {
    const expiredDateUtc = momentTimezone.utc(expireTime);

    expiredDateUtc.add(now.utcOffset(), 'minutes');

    const expiredDateToStr = expiredDateUtc.format('YYYY-MM-DD HH:mm:ss');

    return nowToStr >= expiredDateToStr;
  }

  return false;
}

export const isEndSoon = (state, item) => {
  const rtmpStream = item?.rtmpStream;

  const timezone = getTimezone(state);
  const now = momentTimezone.tz(timezone ?? 'PST8PDT');
  const nowDateToStr = now.format('YYYY-MM-DD');
  const nowTimeToStr = now.format('HH:mm:ss');

  if (rtmpStream) {
    const { expireTime } = rtmpStream;
    const expiredDateUtc =  momentTimezone.utc(expireTime);

    expiredDateUtc.add(now.utcOffset(), 'minutes');

    const expiredDateToStr = expiredDateUtc.format('YYYY-MM-DD');
    const expiredTimeToStr = expiredDateUtc.format('HH:mm:ss');

    return (nowDateToStr === expiredDateToStr) && (nowTimeToStr <= expiredTimeToStr);
  }

  return false;
}

export const getEndSoonTime = (state, item) => {
  const rtmpStream = item?.rtmpStream;
  const timezone = getTimezone(state);
  const now = momentTimezone.tz(timezone ?? 'PST8PDT');

  const { expireTime } = rtmpStream;
  const expiredDateUtc =  momentTimezone.utc(expireTime);
  expiredDateUtc.add(now.utcOffset(), 'minutes');

  return expiredDateUtc.diff(now.clone().startOf('day'), 'seconds');
}

const calcWithAds = (state) => {
  const { streamList, selectedPlatform } = state.currentHls;

  let adsTimerPoints = [];
  let totalTime      = 0
  let adsTimer       = state.currentHls?.adsTimer?.timer?.timer;
  let adsDuration    = 0;

  if (selectedPlatform) {
    const adByPlatform = state.currentHls?.adsTimer?.timer?.adsByPlatform.find( (adByPlatform) => adByPlatform.platform === selectedPlatform);
    if (adByPlatform) {
      const filteredAds = state.ads.filter((advertisement) => advertisement.id === adByPlatform.id)

      adsDuration = filteredAds?.[0]?.duration || 0;
    }
  } else {
    adsDuration = state.currentHls?.adsTimer?.timer?.advertisementBlock?.duration ?? 0;
  }

  if (state.currentHls.monetize && adsTimer) {
    while (totalTime <= 86400) {
      totalTime += adsTimer;
      adsTimerPoints.push(totalTime);
      totalTime += adsDuration;
    }
  }

  streamList.forEach((selectedDay) => {
    const selectedStreamIndex = streamList.findIndex(
      (stream) => stream.id === selectedDay.id
    );
    let totalDuration = 0;
    let streamDay = state.currentHls.streamList[selectedStreamIndex];
    streamDay.items
        .forEach((item, index) => {
          if (isExpired(state, item)) {
            streamDay.items[index].duration = 0;
          } else if (isEndSoon(state, item)) {
            const endSoonTime = getEndSoonTime(state, item);
            streamDay.items[index].duration = item.duration > endSoonTime ? endSoonTime : item.duration;
          } else {
            totalDuration += item.duration || 0;
          }

          if (item?.customStart && item?.start && index !== 0 && item.start >= streamDay.items[index - 1].stop) {
            totalDuration += item.start - streamDay.items[index - 1].stop;
          } else {
            streamDay.items[index].customStart = false;
            streamDay.items[index].start = index === 0 ? 0 : streamDay.items[index - 1].stop;
          }

          let adsAdditionalTime = 0;
          if (!item?.isLiveStream) {
            if (state.currentHls.monetize && adsTimer) {
              const adsInsertCount = getCountAdsInDuration(streamDay.items[index].start, streamDay.items[index].start + item.duration, adsTimerPoints, adsDuration);
              adsAdditionalTime = adsInsertCount * adsDuration;
            }

            if (state.currentHls.monetize) {
              item.ads.forEach((ad) => {
                adsAdditionalTime += calcAdAdditionalTimeForSelectedPlatform(state, ad);
              });
            }
          }

          streamDay.items[index].stop = streamDay.items[index].start + (item.duration || 0) + (adsAdditionalTime || 0);

          if (item.key === state.currentHls.selectedTimeItem?.key) {
            state.currentHls.selectedTimeItem = item;
          }
          totalDuration += adsAdditionalTime;
      }
    );

    streamDay.totalDuration = totalDuration;
    state.currentHls.streamList[selectedStreamIndex] = streamDay;
  });
}

const calcAdAdditionalTimeForSelectedPlatform = ( state, ad ) => {
  const { selectedPlatform } = state.currentHls;
  let adsAdditionalTime = 0;

  if (selectedPlatform) {
    const adByPlatform = ad.adsByPlatform.find( (adByPlatform) => adByPlatform.platform === selectedPlatform);
    if (adByPlatform) {
      const filteredAds = state.ads.filter( (advertisement) => advertisement.id === adByPlatform.id )

      adsAdditionalTime += filteredAds?.[0]?.duration || 0;
    }
  } else {
    adsAdditionalTime += ad.duration || 0;
  }

  return adsAdditionalTime;
}

export const hlsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getHlsList.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getHlsList.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      const { rows, totalItems } = action.payload.data || {};
      const { platforms } = action.payload.extra || {};
      state.list = rows;
      state.totalItems = totalItems;
      state.platforms = platforms;
    })
    .addCase(deleteHlsList.fulfilled, (state, action) => {
      let newList = [];
      action.payload.data.ids.forEach((id) => {
        newList = state.list.filter((s) => s._id !== id);
      });
      state.list = newList;
    })
    .addCase(getAdvertisementBlockList.fulfilled, (state, action) => {
      const { rows } = action.payload.data || {};

      rows.forEach( (advertisement) => {
        state.ads.push({
          id: advertisement.id,
          duration: advertisement.duration,
        });
      });
    })
    .addCase(changeHlsPageSize, (state, action) => {
      state.pageSize = action.payload;
      state.currentPage = 1;
    })
    .addCase(changeHlsPage, (state, action) => {
      state.currentPage = action.payload;
    })
    .addCase(getHlsDetail.pending, (state) => {
      state.currentHls.status = LOADING_STATUS.loading;
    })
    .addCase(getHlsDetail.fulfilled, (state, action) => {
      const { platforms } = action.payload.extra || {};
      state.currentHls.status = LOADING_STATUS.success;
      state.currentHls = formatHlsResponse(action.payload.data);
      state.currentHls.timezone = state.currentHls?.timezone ?? initialState?.currentHls?.timezone;
      state.platforms = platforms;

      calcWithAds(state);
    })
    .addCase(changeCurrentHls, (state, action) => {
      const { name, value } = action.payload;
      state.currentHls = {
        ...state.currentHls,
        [name]: value,
      };
    })
    .addCase(savePlatforms.fulfilled, (state, action) => {
      state.platforms = action.payload.data;
      message.success('Platform list updated successfully');
    })
    .addCase(savePlatforms.rejected, () => {
      message.error('Something went wrong!', 5);
    })
    .addCase(changePlatform, (state, action) => {
      state.currentHls.selectedPlatform = action.payload;
      calcWithAds(state);
    })
    .addCase(getFillers.fulfilled, (state, action) => {
      state.currentHls.filler.list = action.payload.data;
    })
    .addCase(saveHls.fulfilled, (_state, action) => {
      const id = action.payload?.data?._id;
      const currentLink = window.location.href;
      if (currentLink.includes('create')) {
        message.success('Created scheduler successfully');
        window.location.href = ROUTE_PATH.hlsEditor.replace(':id', id);
      } else {
        message.success('Updated scheduler successfully', 1);
        window.location.reload();
      }
    })
    .addCase(saveHls.rejected, () => {
      message.error('Something went wrong!', 1);
    })
    .addCase(saverename.fulfilled, (state, action) => {
      message.success('Updated item successfully', 1);
      let index;

      for(index = 0; index<state.currentHls.selectedStream.items.length; index++) {
        if (state.currentHls.selectedStream.items[index].id === action.payload?.data?._id) {
          state.currentHls.selectedStream.items[index].name = action.payload?.data?.name;
          state.currentHls.selectedStream.items[index].description = action.payload?.data?.description;
          state.currentHls.selectedStream.items[index].epnum = action.payload?.data?.epnum;
          state.currentHls.selectedStream.items[index].id1 = action.payload?.data?.id1;
          state.currentHls.selectedStream.items[index].id2 = action.payload?.data?.id2;
        }
      }
      for(index = 0; index<state.currentHls.streamList.length; index++) {
        for(let j=0; j<state.currentHls.streamList[index].items.length; j++) {
          
          if (state.currentHls.streamList[index].items[j].id === action.payload?.data?._id) {
            state.currentHls.streamList[index].items[j].name = action.payload?.data?.name;
            state.currentHls.streamList[index].items[j].description = action.payload?.data?.description;
            state.currentHls.streamList[index].items[j].epnum = action.payload?.data?.epnum;
            state.currentHls.streamList[index].items[j].id1 = action.payload?.data?.id1;
            state.currentHls.streamList[index].items[j].id2 = action.payload?.data?.id2;
          }
        }
      }

      state.currentHls.selectedTimeItem = undefined;
    })
    .addCase(resetCurrentHls, (state) => {
      state.currentHls = initialState.currentHls;
    })
    .addCase(addDayToSchedule, (state) => {
      const initialStream = {
        id: nanoid(),
        title: '',
        items: [],
        multiDates: [],
      };
      state.currentHls.streamList.push(initialStream);
      if (!state.currentHls.selectedDay) {
        state.currentHls.selectedDay = state.currentHls.streamList?.[0]?.id;
      }
    })
    .addCase(clearStream, (state, action) => {
      const dayId = action.payload;
      const { streamList } = state.currentHls;
      const selectedStreamIndex = streamList.findIndex(
        (stream) => stream.id === dayId
      );
      state.currentHls.streamList[selectedStreamIndex].items = [];
      if (state.currentHls.selectedStream) {
        state.currentHls.selectedStream.items = [];
      }
    })
    .addCase(deleteStream, (state, action) => {
      state.currentHls.streamList = state.currentHls.streamList.filter(
        (stream) => stream.id !== action.payload
      );
    })
    .addCase(cloneStream, (state, action) => {
      const clonedStream = state.currentHls.streamList.find(
        (stream) => stream.id === action.payload
      );
      const newItems = clonedStream.items.map((item) => {
        return {
          ...item,
          key: nanoid(),
        };
      });
      const newStream = {
        ...clonedStream,
        id: nanoid(),
        items: newItems,
        showDates: [],
        multiDates: [],
      };
      state.currentHls.streamList.push(newStream);
    })
    .addCase(selectStreamDay, (state, action) => {
      state.currentHls.selectedDay = action.payload;
    })
    .addCase(addStreamList, (state, action) => {
      const { selectedDay, streamList } = state.currentHls;
      const { timelineItems: newStreamItems, dayId } = action.payload;
      const selectedStreamIndex = streamList.findIndex(
        (stream) => stream.id === (dayId || selectedDay || streamList[0].id)
      );
      const oldStreamItems = state.currentHls.streamList[selectedStreamIndex].items;
      state.currentHls.streamList[selectedStreamIndex].items = [
        ...oldStreamItems,
        ...newStreamItems.map((stream) => {
          return {
            ...stream,
            key: nanoid(),
          };
        }),
      ];

      calcWithAds(state);
    })
    .addCase(updateLiveStreamitem, (state, action) => {
      for(let i = 0; i<state.currentHls.streamList.length; i++) {
        for(let j = 0; j<state.currentHls.streamList[i]?.items.length; j++) {
          if(state.currentHls.streamList[i]?.items[j]?.key === action.payload?.key) {
            state.currentHls.streamList[i].items[j].originalName = action.payload?.originalName;
            state.currentHls.streamList[i].items[j].fileName = action.payload?.fileName;
            state.currentHls.streamList[i].items[j].duration = action.payload?.duration;
            state.currentHls.streamList[i].items[j].payPerView = action.payload?.payPerView;
            state.currentHls.streamList[i].items[j].price = action.payload?.price;
          }
        }
      }
      calcWithAds(state);
    })
    .addCase(changeCurrentHlsInfoByKey, (state, action) => {
      const { key, value } = action.payload;
      state.currentHls[key] = value;

      calcWithAds(state)
    })
    .addCase(changeDayInfoByKey, (state, action) => {
      const { dayId, key, value } = action.payload;
      const { streamList } = state.currentHls;
      const selectedStreamIndex = streamList.findIndex(
        (stream) => stream.id === dayId
      );
      state.currentHls.streamList[selectedStreamIndex][key] = value;
    })
    .addCase(setTimeZones, (state, action) => {
      state.timeZones = action.payload ?? [];
    })
    .addCase(selectTimeItem, (state, action) => {
      state.currentHls.selectedTimeItem = action.payload;
    })
    .addCase(previewItemLink, (state, action) => {
      state.currentHls.previewItem = action.payload;
    })
    .addCase(previewItem.fulfilled, (state, action) => {
      state.currentHls.previewItem = action.payload;
    })
    .addCase(previewItemClose, (state, action) => {
      state.currentHls.previewItem = action.payload;
    })
    .addCase(previewStreamLink, (state, action) => {
      state.currentHls.previewStream = action.payload;
    })
    .addCase(previewStream.fulfilled, (state, action) => {
      state.currentHls.previewStream = action.payload;
    })
    .addCase(previewStreamClose, (state, action) => {
      state.currentHls.previewStream = action.payload;
    })
    .addCase(deleteTimelineItem, (state, action) => {
      const { streamList } = state.currentHls;
      const { streamId, timelineItemId } = action.payload;
      const selectedStreamIndex = streamList.findIndex(
        (stream) => stream.id === streamId
      );
      const oldStreamItems =
        state.currentHls.streamList[selectedStreamIndex].items;
      state.currentHls.streamList[
        selectedStreamIndex
      ].items = oldStreamItems.filter((item) => item.key !== timelineItemId);

      calcWithAds(state)
    })
    .addCase(setAdsStartTime, (state, action) => {
      state.currentHls.adsStartTime = action.payload;
    })
    .addCase(addStreamAds, (state, action) => {
      const { streamId, timelineItemKey, ads } = action.payload;
      const { streamList } = state.currentHls;
      const selectedStreamIndex = streamList.findIndex(
        (stream) => stream.id === streamId
      );
      const selectedItemIndex = state.currentHls.streamList[
        selectedStreamIndex
      ].items.findIndex((item) => item.key === timelineItemKey);
      state.currentHls.streamList[selectedStreamIndex].items[
        selectedItemIndex
      ].ads.push(ads);
      state.currentHls.selectedTimeItem.ads.push(ads);
      let totalDuration = 0;
      state.currentHls.streamList[selectedStreamIndex].items.forEach((item) => {
        totalDuration += item.duration || 0;
      });
      state.currentHls.streamList[
        selectedStreamIndex
      ].totalDuration = totalDuration;
      calcWithAds(state);
    })
    .addCase(changeStreamAds, (state, action) => {
      const { streamId, timelineItemKey, ads } = action.payload;
      const { streamList } = state.currentHls;
      const selectedStreamIndex = streamList.findIndex(
        (stream) => stream.id === streamId
      );
      const selectedItemIndex = state.currentHls.streamList[
        selectedStreamIndex
        ].items.findIndex((item) => item.key === timelineItemKey);

      state.currentHls.streamList[selectedStreamIndex].items[selectedItemIndex].ads = state.currentHls.streamList[selectedStreamIndex].items[selectedItemIndex].ads.map((ad) => {
        if (ad.key === ads.key) {
          return {
            ...ad,
            ...ads,
            adsByPlatform: ads.adsByPlatform,
            startTime: ads.startTime,
          }
        }

        return ad;
      });

      state.currentHls.selectedTimeItem.ads = state.currentHls.selectedTimeItem.ads.map((ad) => {
        if (ad.key === ads.key) {
          return {
            ...ad,
            ...ads,
            adsByPlatform: ads.adsByPlatform,
            startTime: ads.startTime,
          }
        }

        return ad;
      });

      let totalDuration = 0;
      state.currentHls.streamList[selectedStreamIndex].items.forEach((item) => {
        totalDuration += item.duration || 0;
      });
      state.currentHls.streamList[
        selectedStreamIndex
        ].totalDuration = totalDuration;

      calcWithAds(state);
    })
    .addCase(deleteStreamAd, (state, action) => {
      const { streamId, timelineItemId, adKey } = action.payload;
      const { streamList } = state.currentHls;
      const selectedStreamIndex = streamList.findIndex(
        (stream) => stream.id === streamId
      );
      const selectedItemIndex = state.currentHls.streamList[
        selectedStreamIndex
      ].items.findIndex((item) => item.id === timelineItemId);
      state.currentHls.streamList[selectedStreamIndex].items[
        selectedItemIndex
      ].ads = state.currentHls.streamList[selectedStreamIndex].items[
        selectedStreamIndex
      ].ads.filter((ad) => (ad.key ? ad.key !== adKey : ad !== adKey));
      state.currentHls.selectedTimeItem.ads = state.currentHls.selectedTimeItem.ads.filter(
        (ad) => (ad.key ? ad.key !== adKey : ad !== adKey)
      );
      calcWithAds(state);
    })
    .addCase(changeItemOrder, (state, action) => {
      const { streamId, itemKey, orderType } = action.payload;
      const { streamList } = state.currentHls;
      const selectedStreamIndex = streamList.findIndex(
        (stream) => stream.id === streamId
      );
      const selectedItemIndex = state.currentHls.streamList[
        selectedStreamIndex
      ].items.findIndex((item) => item.key === itemKey);
      const position = orderType === ORDER_TYPE.next ? selectedItemIndex + 1 : selectedItemIndex - 1;

      state.currentHls.streamList[selectedStreamIndex].items =moveTo(
          selectedItemIndex,
          position,
          state.currentHls.streamList[selectedStreamIndex].items
      );

      calcWithAds(state);
    })
    .addCase(changeItemOrderDragAndDrop, (state, action) => {
      const { streamId, newIndex, oldIndex } = action.payload;
      const { streamList } = state.currentHls;
      const selectedStreamIndex = streamList.findIndex(
          (stream) => stream.id === streamId
      );

      const element = state.currentHls.streamList[selectedStreamIndex].items[oldIndex];
      state.currentHls.streamList[selectedStreamIndex].items.splice(oldIndex, 1);
      state.currentHls.streamList[selectedStreamIndex].items.splice(newIndex, 0, element);

      calcWithAds(state);
    })
    .addCase(saveEPGIcon.fulfilled, (state, action) => {
      state.currentHls.epgicon = action.payload.epgicon;
    })
    .addCase(addFiller, (state, action) => {
      if (!state.currentHls.fillers) {
        state.currentHls.fillers = [];
      }

      let existEl = state.currentHls.fillers.find(item => item.id === action.payload.id);
      if (!existEl) {
        state.currentHls.fillers.push(action.payload);
      }
    })
    .addCase(removeFiller, (state, action) => {
      state.currentHls.fillers = state.currentHls.fillers.filter((item) => item.id !== action.payload.id);
    })
    .addCase(setStartItem, (state, action) => {
      const { streamId, itemKey, start } = action.payload;
      const { streamList } = state.currentHls;
      const selectedStreamIndex = streamList.findIndex(
        (stream) => stream.id === streamId
      );

      state.currentHls.streamList[selectedStreamIndex].items = state
        .currentHls
        .streamList[selectedStreamIndex]
        .items
        .map((item) => {
          if (item.key === itemKey) {
            item.customStart = true;
            item.start = start;
            item.stop = start + item.duration;
          }

          return item;
        });

      calcWithAds(state);
    })
    .addCase(addClipboardMetaData.fulfilled, (state, action) => {
      state.currentHls.clipboardMetadata = action.payload?.data?.clipboardMetadata;
    })
    .addCase(deleteClipboardMetaData.fulfilled, (state, action) => {
      state.currentHls.clipboardMetadata = action.payload?.data?.clipboardMetadata;
    })
    .addCase(changeSelectedItems, (state, action) => {
      state.currentHls.selectedItems = action.payload;
    })
    .addCase(applyClipboardToMediaItems.fulfilled, (state, action) => {
      message.success('Clipboard was apply successfully');
    })
    .addCase(applyClipboardToMediaItems.rejected, (state, action) => {
      message.error('Clipboard apply error', 5);
    })
});
