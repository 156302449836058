import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';
import {nanoid} from "nanoid";

export const getFillers = createAsyncThunk('getFillers', async (filter) => {
  const response = await API.get(API_ROUTES.fillers, { params: filter});
  return response.data;
});


export const changeFillerFilter = createAction(
  'changeFillerFilter',
  function prepare(filter) {
    return {
      payload: filter,
    };
  }
);

export const previewItemClose = createAction(
    'previewItemClose',
    function prepare(item) {
        return { payload: item };
    }
)
export const previewItem = createAsyncThunk('previewItem', async (item) => {
    if(!item) {
        return {payload: item};
    } else {
        const response = await API.post(API_ROUTES.fileLink, item);
        return response.data;
    }

});



export const addFillerApi = createAsyncThunk('addFillerTest', async (id) => {
    const response = await API.post(API_ROUTES.changeFile, {
        id: id,
        filler: true
    });
    return response.data;
});
export const removeFillerApi = createAsyncThunk('addFillerTest', async (item) => {
    await API.post(API_ROUTES.changeFile, {
        id: item._id ?? item.id,
        filler: false
    });
    return {
        payload: item._id ?? item.id
    }

});

export const addFiller = createAction('addFiller', function prepare(item) {
    let filler = {
        _id: item._id,
        originalName: item.originalName,
        ads: [],
        key: nanoid(),
        fileName: item.fileName,
        bucket: item.bucket,
        duration: item.duration,
        description: item?.description || '',
        epnum: item?.epnum || ''
    };

    return {
        payload: filler,
    };
});

export const removeFiller = createAction('removeFiller', function prepare(item) {
    return {
        payload: item,
    };
});

